.container>.post>.contents {
    line-height: 2;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-shadow: 0 1px 0 $background-color;

    blockquote {
        padding: 0.25rem 1rem;

        @extend %body-font-oblique;

        &.notice {
            border: 1px dotted var(--mid-color);
            outline: 1ex solid $background-color;
            background-color: $background-color;
            color: var(--mid-color);
            text-align: justify;
        }
    }

    figure {
        padding-bottom: 0.5rem;
        text-align: center;

        &>* {
            max-width: 100%;
        }

        &>:is(img, svg, video, audio):first-child {
            padding: 1ex;
            border: 1px dotted var(--mid-color);
            background-color: $background-color;
            outline: 1ex solid $background-color;
        }

        figcaption {
            color: var(--mid-color);
            text-align: left;
            font-style: italic;
            padding: 0 1rem;
        }
    }

    figure>blockquote>p {
        text-align: left;
    }

    code {
        font-size: 0.875rem;
        // border: 1px dotted rgba($foreground-color, 25%);
        border: 1px dotted var(--mid-color);
        background-color: $background-color;
        border-radius: 0.125rem;
        padding: 0 0.25rem;
        @extend %code-font;
    }

    a>code {
        border-color: $mix-background-color;
    }

    pre {
        padding: 0.5rem;
        border: 1px dotted var(--mid-color);
        line-height: 1.5;
        background-color: $background-color;
        @extend %code-font;
        font-size: 0.875rem;
        overflow-y: auto;

        code {
            border: 0 none transparent;
            border-radius: 0;
            padding: 0;
            font-size: 1rem;
            line-height: 1.575rem;
        }
    }

    %inner-code {
        padding: 0;
        border: 0 none transparent;
        border-radius: 0;
        background-color: transparent;
        line-height: inherit;
        font-size: 0.875rem;
    }

    .highlighter-rouge>table {
        width: 100%;
        padding: 0.5rem;
        border: 1px dotted var(--mid-color);
        line-height: 1.5;
        background-color: $background-color;
        @extend %code-font;
        font-size: 0.875rem;
        overflow-y: auto;
        outline: 1ex solid $background-color;
        display: block;

        &>tbody>tr>td.rouge-gutter>pre {
            color: $foreground-color;
            opacity: 0.5;
            padding-right: 0.5rem;
            line-height: 1.575rem;
            font-size: 0.75rem;
        }

        &>tbody>tr>td>pre {
            @extend %inner-code;
            line-height: 1.575rem;
        }
    }

    .highlighter-rouge>table>pre {
        outline: 1ex solid var(--background-color);

        &>code>table {
            pre {
                @extend %inner-code;
            }

            // .rouge-gutter.gl {
            //     padding-right: 0.5rem;
            //     color: $foreground-color;
            //     opacity: 0.5;

            //     &>.lineno {
            //         font-size: 0.75rem;
            //         line-height: 1.575rem;
            //     }
            // }
        }
    }

    a {
        @extend %link-underline;

        &[href^="http://"]:after,
        &[href^="https://"]:after {
            content: " ";
            $image: url("/assets/external-link.svg");
            mask-image: $image;
            -webkit-mask-image: $image;
            background-color: currentColor;
            width: 12px;
            height: 12px;
            margin-left: 0.25rem;
            display: inline-block;
        }
    }

    img,
    video,
    audio,
    svg {
        max-width: 100%;
        max-height: 66vh;
        background-color: $background-color;
        outline: 1px solid $background-color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %header-font;
        // font-weight: 600;
        color: $main-color;
        padding-bottom: 0.125em;
        padding-top: 0.5em;
    }

    b {
        font-weight: 500;
    }

    hr {
        border: 0 none transparent;
        border-top: 1px dotted var(--mid-color);
    }

    ul {
        list-style: none;
        margin-left: 2rem;
        padding: 0;

        &>li {
            margin-bottom: 0.5rem;
            position: relative;

            &:before {
                content: "-";
                position: absolute;
                right: calc(100% + 1rem);
                color: $main-color;
                display: inline-block;
                text-align: right;
            }
        }
    }

    ol {
        counter-reset: li;
        margin-left: 2rem;

        &>li {
            margin-bottom: 0.5rem;
            counter-increment: li;
            position: relative;

            &:before {
                content: counter(li);
                position: absolute;
                right: calc(100% + 1rem);
                color: $main-color;
                display: inline-block;
                text-align: right;
            }
        }
    }

    .footnotes {
        color: var(--mid-color);
    }

    .spoiler {
        background-color: transparent;
        padding: 0 0.25rem;

        &,
        &>* {
            transition: background-color 0.25s, opacity 0.25s;
        }

        &:not(:hover):not(.discard)>*,
        &:not(:hover):not(.discard) {
            background-color: currentColor;
        }

        &>* {
            opacity: 0;
        }
    }
}
