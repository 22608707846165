.highlighter-rouge {

  // token :Text, '' do
  //   token :Whitespace, 'w'
  // end

  // token :Escape, 'esc'
  // token :Error,  'err'
  // token :Other,  'x'

  // token :Keyword, 'k' do
  //   token :Constant,    'kc'
  //   token :Declaration, 'kd'
  //   token :Namespace,   'kn'
  //   token :Pseudo,      'kp'
  //   token :Reserved,    'kr'
  //   token :Type,        'kt'
  //   token :Variable,    'kv'
  // end

  .k,
  .kc,
  .kn,
  .kd,
  .kp,
  .kr,
  .kt,
  /*.n,*/
  .nc,
  .no,
  /* .nn,*/
  .nb {
    font-weight: bold;
  }

  .k,
  .kc,
  .kn,
  .kp,
  .kr {
    color: var(--alt-color);
  }

  // token :Name, 'n' do
  //   token :Attribute,    'na'
  //   token :Builtin,      'nb' do
  //     token :Pseudo,     'bp'
  //   end
  //   token :Class,        'nc'
  //   token :Constant,     'no'
  //   token :Decorator,    'nd'
  //   token :Entity,       'ni'
  //   token :Exception,    'ne'
  //   token :Function,     'nf' do
  //     token :Magic,      'fm'
  //   end
  //   token :Property,     'py'
  //   token :Label,        'nl'
  //   token :Namespace,    'nn'
  //   token :Other,        'nx'
  //   token :Tag,          'nt'
  //   token :Variable,     'nv' do
  //     token :Class,      'vc'
  //     token :Global,     'vg'
  //     token :Instance,   'vi'
  //     token :Magic,      'vm'
  //   end
  // end

  .nd {
    color: $main-color;
  }

  // token :Literal,      'l' do
  //   token :Date,       'ld'

  //   token :String,      's' do
  //     token :Affix,     'sa'
  //     token :Backtick,  'sb'
  //     token :Char,      'sc'
  //     token :Delimiter, 'dl'
  //     token :Doc,       'sd'
  //     token :Double,    's2'
  //     token :Escape,    'se'
  //     token :Heredoc,   'sh'
  //     token :Interpol,  'si'
  //     token :Other,     'sx'
  //     token :Regex,     'sr'
  //     token :Single,    's1'
  //     token :Symbol,    'ss'
  //   end

  //   token :Number,     'm' do
  //     token :Bin,      'mb'
  //     token :Float,    'mf'
  //     token :Hex,      'mh'
  //     token :Integer,  'mi' do
  //       token :Long,   'il'
  //     end
  //     token :Oct,      'mo'
  //     token :Other,    'mx'
  //   end
  // end

  .l,
  .ld,
  .m,
  .mb,
  .mf,
  .mh,
  .mi,
  .mo,
  .il,
  .mo,
  .mx,
  .s,
  .sa,
  .sb,
  .sc,
  .dl,
  .sd,
  .s2,
  .se,
  .sh,
  .si,
  .sx,
  .sr,
  .s1,
  .ss {
    color: $main-color;
  }

  // token :Operator, 'o' do
  //   token :Word,   'ow'
  // end

  .o,
  .ow {
    font-weight: bold;
  }

  // token :Punctuation, 'p' do
  //   token :Indicator, 'pi'
  // end

  // token :Comment,       'c' do
  //   token :Hashbang,    'ch'
  //   token :Doc,         'cd'
  //   token :Multiline,   'cm'
  //   token :Preproc,     'cp'
  //   token :PreprocFile, 'cpf'
  //   token :Single,      'c1'
  //   token :Special,     'cs'
  // end

  .c,
  .ch,
  .cd,
  .cm,
  .cp,
  .cpf,
  .c1,
  .cs {
    font-style: italic;
    color: var(--comment-color);
  }

  // token :Generic,      'g' do
  //   token :Deleted,    'gd'
  //   token :Emph,       'ge'
  //   token :Error,      'gr'
  //   token :Heading,    'gh'
  //   token :Inserted,   'gi'
  //   token :Output,     'go'
  //   token :Prompt,     'gp'
  //   token :Strong,     'gs'
  //   token :Subheading, 'gu'
  //   token :Traceback,  'gt'
  //   token :Lineno,     'gl'
  // end



  .err {
    color: #f64848;
  }
}
