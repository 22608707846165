html,
body {
    background-color: $background-color;
    color: $foreground-color;
    @extend %body-font;
}

body>.container {
    margin: 0 auto;
    max-width: 79ch;
    padding: 2rem 0;

    &>.top {
        padding-left: 1rem;
        padding-bottom: 1.5rem;

        a {
            display: inline-block;
            text-decoration: none;
        }

        h1 {
            color: $main-color;
            @extend %header-font;
            font-size: 3rem;
        }
    }

    &>.pagination {
        margin-top: 2rem;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;

        &>a {
            color: $main-color;
            text-decoration: none;
            border: 1px dotted $main-color;
            background-color: var(--transparent-color);
            padding: 0 1ex;
            outline: 0.5ex solid var(--transparent-color);

            &:hover {
                border-style: solid;
            }

            &.selected {
                border-style: solid;
                background-color: $main-color;
                color: $background-color;
                border-color: $background-color;
                outline-color: $main-color;
            }
        }
    }

    &>.post-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &>.post-list>.post,
    &>.post {
        padding: 1rem;
        border: 1px dotted $mix-background-color;
        background-color: var(--transparent-color);
        outline: 1ex solid var(--transparent-color);

        @media only screen and (max-width: 79ch) {
            border-left: 0 none transparent;
            border-right: 0 none transparent;
        }

        &>header {
            margin-bottom: 0.5rem;

            &>.post-title-link {
                display: contents;
            }

            &>.post-title-link>.post-title,
            &>.post-title {
                @extend %header-font;
                font-size: 1.5rem;
                line-height: 1;
                color: $main-color;
                position: relative;
            }

            &>.post-info {
                color: $main-color;
            }
        }
    }

    &>.post-list>.post>.contents>.post-description {
        padding-bottom: 1rem;
    }

    &>.post-list>.post>.contents a.rm {
        @extend %link-underline;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
        line-height: 1;
    }


}

body>footer {
    margin-top: 2rem;
    width: 100%;
    position: sticky;
    top: 100%;
    padding: 2rem 0;
    border-top: 1px dotted $mix-background-color;
    background-color: var(--transparent-color);
    outline: 1ex solid var(--transparent-color);
    // @extend %general-shadow;

    &>.container {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 79ch;
        margin: 0 auto;
        font-size: 0.75rem;

        &>.left {
            display: contents;
        }

        &>.left>ul {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            text-align: right;

            &>li>a {
                @extend %link-underline;
            }
        }

        &>.right {
            p {
                max-width: 69ch;
            }
        }
    }
}
