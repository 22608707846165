$_main-color: hsl(225, 71%, 50%);
$_dark-main-color: hsl(152, 71%, 50%);
$_black-color: rgb(17, 18, 29);
$_white-color: invert($_black-color); // 238, 237, 226

:root {
    --main-color: #{$_main-color};
    --alt-color: #{adjust-color($_main-color, $hue: 180)};
    --comment-color: #{adjust-color($_main-color, $saturation: -100%)};
    --background-color: #{$_white-color};
    --foreground-color: #{$_black-color};
    --mix-foreground-color: #{mix($_main-color, $_black-color, 50%)};
    --mix-background-color: #{mix($_main-color, $_white-color, 50%)};
    --mid-color: #{mix($_white-color, $_black-color, 50%)};
    --transparent-color: #{rgba($_main-color, 0.05)};
}

@media (prefers-color-scheme: dark) {
    :root {
        --main-color: #{$_dark-main-color};
        --alt-color: #{adjust-color($_dark-main-color, $hue: 180)};
        --comment-color: #{adjust-color($_dark-main-color, $saturation: -100%)};
        --background-color: #{$_black-color};
        --foreground-color: #{$_white-color};
        --mix-foreground-color: #{mix($_dark-main-color, $_white-color, 50%)};
        --mix-background-color: #{mix($_dark-main-color, $_black-color, 50%)};
        --transparent-color: #{rgba($_dark-main-color, 0.05)};
    }
}

$main-color: var(--main-color);
$foreground-color: var(--foreground-color);
$background-color: var(--background-color);
$mix-foreground-color: var(--mix-foreground-color);
$mix-background-color: var(--mix-background-color);
$mid-color: var(--mid-color);


@import "_highlight.scss";
@import "_mixins.scss";
@import "_reset.scss";
@import "_post.scss";
@import "_inner.scss";
