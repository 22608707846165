%header-font {
    font-family: 'VT323', serif;
    // font-weight: 600;
}

%body-font {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

%body-font-bold {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

%body-font-oblique {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-style: oblique;
    // font-family: 'Flow Rounded' !important;
}

%code-font {
    font-family: 'JetBrains Mono', 'Courier New', Courier, monospace;
    font-size: 1.2rem;
    line-height: 1.3;
    // font-family: 'Flow Rounded' !important;
}

%code-font-oblique {
    font-family: 'JetBrains Mono', 'Courier New', Courier, monospace;
    font-size: 1.2rem;
    line-height: 1.3;
    // font-family: 'Flow Rounded' !important;
}

%link-underline {
    color: $main-color;
    padding: 0.125rem 0;
    font-weight: normal;
    text-decoration: none;
    position: relative;

    &:before {
        background-color: currentColor;
        content: '';
        display: block;
        height: 1px;
        width: 0;
        position: absolute;
        bottom: 0;
        left: 0.1rem;
        transition: width 0.2s ease-in-out;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    &:hover:before {
        width: calc(100% - 0.2rem);
    }
}

// %general-shadow {
//     box-shadow: 0 1.25rem 1.625rem -0.3125rem rgba(0, 0, 0, 0.1), 0 0.5rem 0.625rem -0.375rem rgba(0, 0, 0, 0.1);
// }
